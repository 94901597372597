var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page_temp page_temp_left"},[_c('div',{staticClass:"appearance"},[_c('item-title',{attrs:{"name":'村容村貌',"english":"Village Appearance"}}),_c('div',{staticClass:"appearance-box scrollbar"},[_c('line-border-box',{attrs:{"type":"bottom"}}),_c('div',{staticClass:"content_img_box appearance_content"},_vm._l((_vm.appearanceList),function(item,index){return _c('div',{key:index,staticStyle:{"width":"100%","overflow":"hidden"}},[_c('environment-item',{attrs:{"title":item.title,"environmentalType":item.environmentalType,"detailContent":item.detailContent,"layerName":item.type == 'addPoint' && !item.layerName
                                        ? 't03_xqd'
                                        : item.layerName,"ifshow":item.ifShow == 2 ? true : false,"markerUrl":item.markerUrl,"type":item.type,"img":item.img,"queryParameter":item.queryParameter
                                        ? item.queryParameter
                                        : item.type == 'addPoint' &&
                                          !item.layerName
                                        ? `mc='${item.title}'`
                                        : ''},on:{"toggleOverlay":function($event){return _vm.changeIfShow(item)}}})],1)}),0)],1)],1),_c('div',{staticClass:"lavatory",staticStyle:{"position":"relative"}},[_c('item-title',{attrs:{"name":'厕所革命',"english":"Source Han Sans CN-Medium"}}),_c('div',{staticClass:"content_img_box"},[_c('line-border-box',{attrs:{"type":"bottom"}}),_c('div',{staticStyle:{"display":"flex","padding-right":"0.4rem","justify-content":"space-between","width":"100%"}},_vm._l((_vm.toiletList),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"clickContent"},[_c('arc-environment',{attrs:{"index":index,"singular":item.value,"sum":_vm.toiletList.total,"color":item.color}}),_c('div',{staticClass:"round"},[_vm._v(_vm._s(item.value))])],1),_c('div',{staticClass:"count"},[_c('p',[_vm._v(_vm._s(item.name))]),_c('h3',[_vm._v(_vm._s(item.value)+" "),_c('cite',[_vm._v("所")])])])])}),0)],1),_c('percentage',{attrs:{"width":Number(_vm.width),"type":"percentage"}})],1),_c('div',{staticClass:"water",staticStyle:{"margin-top":"0.08rem"}},[_c('item-title',{attrs:{"name":'乡村水利',"english":"Rural water conservancy"}}),_c('div',{staticClass:"content_img_box"},[_c('line-border-box',{attrs:{"type":"bottom"}}),_vm._l((_vm.waterList),function(item,index){return _c('div',{key:index,staticClass:"item",staticStyle:{"width":"48%"}},[_c('environment-item',{attrs:{"title":item.title,"environmentalType":item.environmentalType,"layerName":item.type == 'addPoint' && !item.layerName
                                    ? 't03_xqd'
                                    : item.layerName,"ifshow":item.ifShow == 2 ? true : false,"markerUrl":item.markerUrl,"width":35,"height":40,"type":item.type,"img":item.img,"queryParameter":item.queryParameter
                                    ? item.queryParameter
                                    : item.type == 'addPoint' && !item.layerName
                                    ? `mc='${item.title}'`
                                    : ''},on:{"toggleOverlay":function($event){return _vm.changeIfShow(item)}}})],1)})],2)],1)]),_c('div',{staticClass:"page_temp page_temp_right"},[_c('div',{staticClass:"contentBox"},[_c('div',{staticClass:"infrastructure"},[_c('item-title',{attrs:{"name":"乡村基建","english":"rural infrastructure"}}),_c('div',{staticClass:"content_img_box"},[_c('line-border-box',{attrs:{"type":"bottom"}}),_vm._l((_vm.infrastructureList),function(item,index){return _c('div',{key:index,staticStyle:{"width":"100%"}},[_c('environment-item',{attrs:{"title":item.title,"unit":item.unit,"environmentalType":item.environmentalType,"layerName":item.type == 'addPoint' && !item.layerName
                                        ? 't03_xqd'
                                        : item.layerName,"ifshow":item.ifShow == 2 ? true : false,"markerUrl":item.markerUrl,"width":35,"height":40,"type":item.type,"img":item.img,"queryParameter":item.queryParameter
                                        ? item.queryParameter
                                        : item.type == 'addPoint' &&
                                          !item.layerName
                                        ? `mc='${item.title}'`
                                        : ''},on:{"toggleOverlay":function($event){return _vm.changeIfShow(item)}}})],1)})],2)],1),_c('div',{staticClass:"sewage"},[_c('item-title',{attrs:{"name":"垃圾污水","english":"Garbage sewage"}}),_c('div',{staticClass:"content_img_box",staticStyle:{"flex-wrap":"nowrap"}},[_c('line-border-box',{attrs:{"type":"bottom"}}),_vm._l((_vm.sewageList),function(item,index){return _c('div',{key:index},[_c('environment-item',{attrs:{"width":26,"height":30,"title":item.title,"environmentalType":item.environmentalType,"layerName":item.type == 'addPoint' && !item.layerName
                                        ? 't03_xqd'
                                        : item.layerName,"ifshow":item.ifShow == 2 ? true : false,"markerUrl":item.markerUrl,"type":item.type,"img":item.img,"queryParameter":item.queryParameter
                                        ? item.queryParameter
                                        : item.type == 'addPoint' &&
                                          !item.layerName
                                        ? `mc='${item.title}'`
                                        : ''},on:{"toggleOverlay":function($event){return _vm.changeIfShow(item)}}})],1)})],2)],1),_c('div',{staticClass:"power"},[_c('item-title',{attrs:{"name":"乡村电力","english":"Source Han Sans CN-Medium"}}),_c('div',{staticClass:"content_img_box"},[_c('line-border-box',{attrs:{"type":"bottom"}}),_vm._l((_vm.powerList),function(item,index){return _c('div',{key:index,staticClass:"item",staticStyle:{"width":"48%"}},[_c('environment-item',{attrs:{"title":item.title,"environmentalType":item.environmentalType,"layerName":item.type == 'addPoint' && !item.layerName
                                        ? 't03_xqd'
                                        : item.layerName,"ifshow":item.ifShow == 2 ? true : false,"markerUrl":item.markerUrl,"width":35,"height":40,"type":item.type,"img":item.img,"queryParameter":item.queryParameter
                                        ? item.queryParameter
                                        : item.type == 'addPoint' &&
                                          !item.layerName
                                        ? `mc='${item.title}'`
                                        : ''},on:{"toggleOverlay":function($event){return _vm.changeIfShow(item)}}})],1)})],2)],1)])]),_c('div',{staticClass:"btn-box"},[(_vm.isShow)?_c('a',{staticClass:"btn-img",attrs:{"href":"#"},on:{"click":function($event){return _vm.requestVideoData()}}}):_vm._e()]),_c('dot-pop',{directives:[{name:"show",rawName:"v-show",value:(_vm.v_if_show_video),expression:"v_if_show_video"}],attrs:{"name":'视频',"width":800,"height":500,"type":"video","x":_vm.v_video.x,"y":_vm.v_video.y,"z":1},on:{"closePane":_vm.onCloseVideo}},[_c('div',{staticStyle:{"width":"100%","height":"100%","display":"none"},attrs:{"id":"id_video_play"}}),_c('easy-player',{staticStyle:{"width":"100%","height":"100%"},attrs:{"id":"player","live":"true","show-custom-button":"true","isH265":"true"}})],1),_vm._l((_vm.v_marker_data),function(item,index){return (_vm.v_bln_show_marker)?_c('div',{key:index},[_c('dot-pop',{attrs:{"type":"dot","name":item.mc,"x":item.x,"y":item.y,"z":1}},[_c('img',{staticStyle:{"width":"30px"},attrs:{"src":_vm.markerUrl},on:{"click":function($event){return _vm.showImg(item)}}})])],1):_vm._e()}),_c('dot-pop',{directives:[{name:"show",rawName:"v-show",value:(_vm.v_if_show_img),expression:"v_if_show_img"}],attrs:{"name":_vm.title,"type":"img","x":_vm.v_item.x,"y":_vm.v_item.y,"z":1,"width":500,"height":400},on:{"closePane":_vm.onCloseImg}},[_c('img',{staticStyle:{"height":"370px"},attrs:{"src":_vm.img}})]),(_vm.v_show_pop)?_c('dot-pop',{attrs:{"name":_vm.v_pop_message.name,"width":400,"height":300,"x":_vm.v_pop_message.x,"y":_vm.v_pop_message.y,"z":_vm.v_pop_message.z},on:{"closePane":function($event){_vm.v_show_pop=false}}},[_c('div',{staticClass:"cls-pop-message-body"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.v_pop_message.description)}})])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }