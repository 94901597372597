<template>
    <div class="component-box-circle" v-if="type == 'circle'">
        <div class="box">
            <div class="item-1"></div>
            <div class="item-2"></div>
            <div class="item-3"></div>
            <div class="text">100%</div>
        </div>
    </div>
    <div class="component-box-columnar" v-else-if="type == 'columnar'">
        <span class="title">任务完成率</span>
        <div class="columnar-box">
            <span>{{ width + '%' }}</span>
            <div class="content">
                <div><span :style="{ width: width + '%' }"></span></div>
                <div><span :style="{ width: 100 - width + '%' }"></span></div>
            </div>
            <span>{{ 100 - width + '%' }}</span>
        </div>
    </div>
    <div class="component-box-percentage" v-else-if="type == 'percentage'">
        <span class="title">任务完成率</span>
        <div
            style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-right: 0.2rem;
                position: relative;
            "
        >
            <div class="percentage-box">
                <div class="box" :style="{ width: width + '%' }">
                    <div class="bg"></div>
                    <!-- <img src="../assets/img/server/c1.png" alt="" /> -->
                </div>
            </div>
            <span
                style="
                    transform: translateY(-0.02rem);
                    color: aqua;
                    position: absolute;
                    top: -0.25rem;
                    right: 0;
                    color: #33a0ff;
                    font-size: 0.2rem;
                "
                >{{ width + '%' }}</span
            >
        </div>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'circle'
        },
        width: {
            type: Number,
            default: 100
        }
    }
}
</script>

<style lang="scss">
.component-box-circle {
    width: 1.8rem;
    height: 1.8rem;
    margin: 0 auto;

    .box {
        width: 100%;
        height: 100%;
        border: 1px solid rgba(0, 255, 255, 0.928);
        box-shadow: inset 0 0 0.03rem 0.02rem rgba(0, 255, 255, 0.928);
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        .item-1 {
            width: 170%;
            height: 170%;
            background-color: rgba(0, 255, 255, 0.442);
            border-radius: 30%;
            position: absolute;
            top: 60%;
            left: -40%;
            animation: test 9s linear infinite;
            -webkit-animation: test 9s linear infinite;
            /*Webkit内核的浏览器（如 Safari、Chrome）*/
            -moz-animation: test 9s linear infinite;
            /*gecko内核的浏览器（如 Firefox）*/
            -o-animation: test 9s linear infinite;
            /*Opera 浏览器*/
            -ms-animation: test 9s linear infinite;
            /*IE8+*/
        }

        .item-2 {
            width: 150%;
            height: 150%;
            background-color: rgba(0, 255, 255, 0.498);
            border-radius: 30%;
            position: absolute;
            top: 60%;
            left: 20%;
            animation: test 7s 0.5s linear infinite;
            -webkit-animation: test 7s 0.5s linear infinite;
            /*Webkit内核的浏览器（如 Safari、Chrome）*/
            -moz-animation: test 7s 0.5s linear infinite;
            /*gecko内核的浏览器（如 Firefox）*/
            -o-animation: test 7s 0.5s linear infinite;
            /*Opera 浏览器*/
            -ms-animation: test 7s 0.5s linear infinite;
            /*IE8+*/
        }

        .item-3 {
            width: 120%;
            height: 120%;
            background-color: rgba(0, 255, 255, 0.583);
            border-radius: 30%;
            position: absolute;
            top: 70%;
            left: 0%;
            animation: test 7s 0.7s linear infinite;
            -webkit-animation: test 7s 0.7s linear infinite;
            /*Webkit内核的浏览器（如 Safari、Chrome）*/
            -moz-animation: test 7s 0.7s linear infinite;
            /*gecko内核的浏览器（如 Firefox）*/
            -o-animation: test 7s 0.7s linear infinite;
            /*Opera 浏览器*/
            -ms-animation: test 7s 0.7s linear infinite;
            /*IE8+*/
        }

        .text {
            font-size: 40%;
            color: #fff;
            z-index: 9;
            transform: translateY(-40%);
        }
    }
}

@keyframes test {
    0% {
        transform: rotateZ(0);
    }

    100% {
        transform: rotateZ(360deg);
    }
}

.component-box-columnar {
    width: 100%;
    height: 1rem;
    font-size: 0.14rem;
    padding: 0.1rem 0;

    .title {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        font-size: 0.14rem;
        color: #fff;
        margin: 0.1rem 0;
    }

    .columnar-box {
        display: flex;
        justify-content: space-between;

        .content {
            flex: 1;
            height: 0.3rem;
            display: flex;

            div {
                width: 50%;
                height: 100%;
            }

            div:first-child {
                border: 1px solid #3ea2ff;
                box-shadow: inset 0 0 0.08rem 0.01rem #007df2;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                span {
                    background-color: #45b4f0;
                    box-shadow: inset 0 0 0.08rem 0.01rem #007df2;
                    display: flex;
                    width: 100%;
                    height: 100%;
                }
            }

            div:last-child {
                border: 1px solid #ffc748;
                box-shadow: inset 0 0 0.08rem 0.01rem #ffc748;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                span {
                    background-color: #f5c85e;
                    box-shadow: inset 0 0 0.08rem 0.01rem #f2b015;
                    display: flex;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 0.5rem;
        }

        span:first-child {
            color: #3ea2ff;
        }

        span:last-child {
            color: #ffc748;
        }
    }
}

.component-box-percentage {
    font-size: 0.14rem;
    color: #fff;
    padding: 0 0.2rem;
    width: 100%;
    box-sizing: border-box;
    margin-top: 0.15rem;
    // height: .16rem;
    .title {
        font-size: 0.14rem;
        color: #00ffff;
        padding-left: 0.15rem;
        position: relative;
        &::before {
            content: '';
            width: 0.06rem;
            height: 0.06rem;
            position: absolute;
            top: 0.06rem;
            left: 0;
            background: #00ffff;
            // filter: blur(4px);
        }
    }
    .percentage-box {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0.1rem 0;
        box-sizing: border-box;
        color: rgba(0, 255, 255, 0.928);
        .box {
            height: 100%;
            overflow: hidden;
            padding: 0.1rem 0;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                top: 0.05rem;
                right: 0;
                background: url('~@/assets/img/server/bar-img.png') no-repeat;
                background-size: 100% 100%;
                height: 0.24rem;
                width: 0.24rem;
            }
            img {
                width: 2.4rem;
                height: 0.15rem;
            }
            .bg {
                display: inline-block;
                // width: 2.4rem;
                width: 100%;
                height: 0.15rem;
                background: linear-gradient(
                    270deg,
                    #188ef5 0%,
                    rgba(24, 142, 245, 0.3) 100%
                );
                border-radius: 0.03rem 0.03rem 0.03rem 0.03rem;
            }
        }
    }
}
</style>