<template>
    <div
        :id="'arc_echarts_' + index"
        class="arc_echarts"
        style="width: 3.22rem; height: 3.22rem"
    ></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    props: {
        sum: {
            type: Number,
            default: 100
        },
        singular: {
            type: Number,
            default: 6
        },
        index: Number,
        color: {
            type: String,
            default: '#498DF3'
        }
    },
    data: function () {
        return {
            chart: null
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.init()
        })
    },
    watch: {
        singular(newV, oldV) {
            this.init()
        }
    },
    methods: {
        init() {
            let that = this
            let arr = []
            arr[0] = (this.singular / this.sum) * 100
            let option = {
                angleAxis: {
                    max: 100,
                    // 隐藏刻度线
                    show: false,
                    startAngle: 90
                },
                radiusAxis: {
                    type: 'category',
                    show: true,
                    axisLabel: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false
                    },
                },
                polar: {
                    radius: '150%' //图形大小
                },
                series: [{
                    type: 'bar',
                    data: arr,
                    showBackground: true,
                    roundCap: true,
                    coordinateSystem: 'polar',
                    barWidth: 4,
                    itemStyle: {
                        normal: {
                            color: this.color
                        }
                    },
                }]
            }
            this.charts = echarts.init(document.getElementById('arc_echarts_' + that.index));
            this.charts.setOption(option);
            window.addEventListener("resize", () => {
                this.charts.resize();
            });
        }
    },
    destroyed() {
        this.charts.dispose();
    }
}
</script>

<style scoped>
.arc_echarts {
    transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
}
</style>
