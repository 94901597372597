<template>
<div>
    <div  class="seamless-warp2">
        <img src="../assets/img/environment/warning.png" alt="">
        <vueSeamless :data="list" :class-option="optionLeft" class="sscroll-container">
          <ul class="item">
            <li v-for="item in list" style="font-size:14px">
                <div class="items">
                    <div class="circle"></div>
                    <div class="title">{{ item }}</div>
                </div>
                
            </li>
          </ul>
        </vueSeamless>
      </div>
</div>
</template>

<script>
import vueSeamless from "vue-seamless-scroll";
export default {
    components: {
    vueSeamless
  },
    data(){
       return {
        list:[
            '2023/07/12 10:20  森林防火报警消息   30分钟之前','2023/07/12 10:20  森林防火报警消息   29分钟之前','2023/07/12 10:20  森林防火报警消息   28分钟之前'
        ],
       }

    },
    created(){

    },
    methods:{
        init(){
        }
    },
    computed: {
    optionLeft() {
      return {
        step:1,
        direction: 2,
        limitMoveNum: 3,
        openWatch: true, // 开启数据实时监控刷新dom
        //  singleWidth: 1/3,
        waitTime: 1000
      };
    }
  },
    mounted(){
        this.$nextTick(()=>{
            this.init()
        })
    }

}
</script>

<style lang="scss" scoped>
.img-box{
    position: absolute;
    top: 0;
    left: 0;
}
.seamless-warp2{
    position: absolute;
    right: 4.5rem;
    left: 4.5rem;
    top: 1.1rem;
    display: flex;
    border: solid 1px #2c8ce0;
    border-radius: 2px;
    background: rgba(4, 43, 102,.75);
    height: 0.36rem;
    line-height: 0.36rem;
    width: calc(100% - 9.3rem);
    z-index: 9;
    overflow-x: hidden;
    padding: 0 0.15rem;
    img{
        height: 0.17rem;
        width: 0.18rem;
        margin: auto;
    }
}
.sscroll-container{
    margin-left: 0.2rem;
    overflow: hidden;
}
ul.item {
    display: flex;
    width: 100%;
    font-size: 0.14rem;
    color: #d5e443;
    li {
      float: left;
      margin-right: 0.8rem;
      list-style: none;
      padding-left: 0.5rem;
      // overflow: hidden;
      .circle{
        float:left;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background: #d5e443;
        margin-top: 0.15rem;
        margin-right: 0.1rem;
        // position: absolute;
        // left: 0;
        // top: calc(50% - 2px);
      }
      .title{
        // display: inline-block;
        white-space: nowrap;
      }
      .items{
        display: block;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
      }
    }
}
</style>