<!--  -->
<template>
    <div>
        <div class="infrastructure-item" v-if="environmentalType == 6" @click="doParentFunctionForToggle" v-bind:class="{'item-active': v_is_active}">
            <div class="img">
                <img :src="img" alt="" />
            </div>
            <p class="bg">{{ title }}</p>
            <div class="amount" v-if="type == 'addLine'">
                <P>累计长度（米）</P>
                <h3>{{ v_length || '0' }}</h3>
            </div>
            <div class="amount" v-else>
                <P>设备数量（{{ unit }}）</P>
                <h3>{{ v_list.length || '0' }}</h3>
            </div>
        </div>
        <div class="sewage-item" v-else-if="environmentalType == 3" @click="doParentFunctionForToggle" v-bind:class="{'item-active': v_is_active}">
            <div class="img">
                <img :src="img" alt="" />
            </div>
            <p>{{ title }}</p>
            <h3>{{ v_list.length || '0' }}
                 <cite>个</cite>
            </h3>
        </div>
        <div class="appearance-item" v-else-if="environmentalType == 1" @click="doParentFunctionForToggle" v-bind:class="{'item-active': v_is_active}">
            <div class="img">
                <img
                    :src="img"
                    alt=""
                    style="
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        -o-object-fit: cover;
                        vertical-align: top;
                    "
                />
            </div>
            <div class="content">
                <div class="title">
                    {{ title }}
                </div>
                <img
                    class="button"
                    :src="require('@/assets/img/server/detailed.png')"
                    alt=""
                />
                <el-tooltip
                    class="item"
                    effect="dark"
                    :content="detailContent"
                    placement="right-end"
                    popper-class="tooltip-width"
                >
                    <p>{{ detailContent }}</p>
                </el-tooltip>
            </div>
        </div>
        <div class="item-one" v-else @click="doParentFunctionForToggle" v-bind:class="{'item-active': v_is_active}">
            <div class="img">
                <img :src="img" alt="" />
            </div>
            <div class="right" v-if="type == 'addLine'">
                <p>{{ v_length || '0' }}<span style="font-size: 10px">米</span></p>
                <p class="name">{{ title }}</p>
            </div>
            <div class="right" v-else>
                <p>{{ v_list.length || '0' }}</p>
                <p class="name">{{ title }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { getDiShiZhouCodeByCunCode, getEnvelopeByList, getEnvelopeByPoints} from '@/util/common.js';
import { getLonLatArray, addPoint, addPolygonFunction, addLine, cleanMarkerListByType, getMakerById, drawCircle } from '@/util/drawShape.js';
import http from '@/util/http.js';
import dotPop from "@/components/dot-pop.vue"
import {
    mapState
} from 'vuex';

var g_list_overlay = [];
var g_bln_height_up = '';//判断是否超过500，超过为true否则false
var g_zu_marker_list_overlay = [];
var g_HEIGHT_CHANG_CAMERA = 1100;

var g_handler_sk = null;

export default {
    components: { dotPop },
    props: {
        environmentalType: Number,
        detailContent: { type: String, default: '-' },
        unit: String,
        title: String,
        layerName: String,
        type: String,
        queryParameter: String,
        img: String,
        typeInfo: String,
        dotPopActive: -1,
        layerType: { type: String, default: 'd' },//d x m  点线面
        fieldName: { type: String, default: 'mc' },
        markerUrl: { type: String, default: require('@/assets/marker/location.png') },
        width: { type: Number, default: 35 },
        height: { type: Number, default: 40 },
        ifshow: { type: Boolean, default: false }
    },
    data() {
        return {
            v_list: [],
            v_show: false,
            g_xqid: localStorage.getItem('tenantCode'),
            g_dsz_id: getDiShiZhouCodeByCunCode(localStorage.getItem('tenantCode')),
            v_dot_color: '#00c996',
            v_show_zu: false,
            v_length: 0,//总长度
            v_zu_list: [],//村小组
            v_is_active: false,
            v_if_show_img: false,
            v_item: {x: 0, y: 0}
        }
    },
    watch: {
        ifshow: function (newVal, oldVal) {
            if (newVal) {
                this.show();
            } else {
                this.hide();
            }
        },
    },
    computed: {
        ...mapState(['menu_active'])
    },
    methods: {
        init() {
            this.queryLayer();
            if (this.layerName == 't04_zmss_dg') {//只在查询垃圾桶时，再查小组信息
                this.requestCunXiaoZuList();
            }
            g_handler_sk = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
        },

        listenHeight: function () {
            var height = viewer.camera.positionCartographic.height;
            var _height_up = height > g_HEIGHT_CHANG_CAMERA ? true : false;
            // console.log('height----', parseInt(height));
            if (_height_up != g_bln_height_up) {
                this.handleMapZoom();
                // g_bln_height_up = _height_up;
            }
        },

        handleMapZoom: function () {
            var height = viewer.camera.positionCartographic.height;
            if (height > g_HEIGHT_CHANG_CAMERA) {//超过500米，显示小组定位，名称，数量
                console.log('heigth>' + g_HEIGHT_CHANG_CAMERA);
                //隐藏垃圾桶
                cleanMarkerListByType('layer');
                this.$bus.$emit('busEventShowMarker', this.v_zu_list, this.markerUrl);
                g_bln_height_up = true;
            } else {
                console.log('height=<' + g_HEIGHT_CHANG_CAMERA);
                //显示所有垃圾桶
                if (g_list_overlay.length == 0) {
                    this.$bus.$emit('busEventShowMarker', this.v_list, this.markerUrl);
                }
                g_bln_height_up = false;
            }
        },

        queryLayer() {
            var _sqlParameter = {
                "getFeatureMode": "SQL",
                "maxFeatures": 3000,
                "datasetNames": ["p" + this.g_dsz_id + ":" + this.layerName],
                "queryParameter": this.queryParameter ? {
                    "attributeFilter": "SSCID= '" + this.g_xqid + "' " + 'and ' + this.queryParameter,
                    "fields": ["smid"]
                } : {
                    "attributeFilter": "SSCID= '" + this.g_xqid + "' ",
                    "fields": ["smid", "mc", "sslb", "sscmc", "cjsj"]
                }
            }
            //线添加长度
            if (this.type == 'addLine'){
                _sqlParameter.queryParameter.fields.push('SmLength');
            }
            //水库
            if (this.layerName == 't02_slss_sk'){
                _sqlParameter.queryParameter.fields.push('bz');
            }
            let x_y = [];
            http.requestIServerWithPage(_sqlParameter, 0, 2000, (result) => {
                // console.log('--回调--', result.totalCount, this.title);
                this.v_list = [];
                if (this.type == 'addPoint') {
                    if (result.features.length > 0) {
                        for (var i = 0; i < result.features.length; i++) {
                            var _feature = result.features[i];
                            var _item = {
                                id: i,
                                smid: _feature.fieldValues[0],
                                name: _feature.fieldValues[1],
                                img: this.img,
                                environmentalType: this.environmentalType,
                                title: this.title,
                                markerUrl: this.markerUrl,
                                x: _feature.geometry.center.x,
                                y: _feature.geometry.center.y,
                                shape: [_feature.geometry.center.x, _feature.geometry.center.y]
                            };
                            this.v_list.push(_item);
                        }
                    }
                } else if (this.type == 'addLine') {
                    if (result.features.length > 0) {
                        this.v_length = 0;
                        for (var j = 0; j < result.features.length; j++) {
                            var dlx_point_jh = result.features[j].geometry.points;
                            x_y = [];
                            for (var i = 0; i < dlx_point_jh.length; i++) {
                                var every_point = dlx_point_jh[i];
                                x_y.push(every_point.x);
                                x_y.push(every_point.y);
                            }
                            this.v_list.push({
                                type: 1,
                                id: j + 1,
                                mc: result.features[j].fieldValues[0],
                                shape: x_y
                            });
                            this.v_length += parseInt(result.features[j].fieldValues[result.features[j].fieldValues.length-1]);
                        }
                        //this.v_length = parseInt(this.v_length/1000);
                        console.log(this.title, '线长度', this.v_length);
                    }
                } else if (this.type == 'addPolygonFunction') {
                    if (result.features.length > 0) {
                        for (var i = 0; i < result.features.length; i++) {
                            var feature = result.features[i];
                            var dlx_point_jh = result.features[i].geometry.points;
                            var _envelope = getEnvelopeByPoints(dlx_point_jh);
                            x_y = [];      //储存面图形的所有点
                            for (var j = 0; j < dlx_point_jh.length; j++) {
                                var every_point = dlx_point_jh[j];
                                x_y.push(every_point.x);
                                x_y.push(every_point.y);
                            }
                            var _item = {
                                id: feature.fieldValues[0],
                                shape: x_y,
                                envelope: _envelope,
                                type: 2,
                                mc: feature.fieldValues[1],
                                szxq: feature.fieldValues[2],
                                cjsj: feature.fieldValues[3]
                            }
                            //水库
                            if (this.layerName == 't02_slss_sk'){
                                var _bz = feature.fieldValues[feature.fieldValues.length-1];
                                _item.bz = _bz;
                                _item.x = feature.geometry.center.x;
                                _item.y = feature.geometry.center.y;
                            }
                            this.v_list.push(_item);
                        }
                    }

                } else if (this.type == 'drawCircle') {
                    for (var i = 0; i < result.features.length; i++) {
                        var feature = result.features[i];
                        var fsbj = 2000;
                        if (feature.fieldValues[2]) {
                            fsbj = parseFloat(feature.fieldValues[2]);
                        }
                        var item = {
                            id: feature.fieldValues[0],
                            mc: feature.fieldValues[1],
                            fsbj: fsbj, //feature.fieldValues[2],
                            type: 'jz',
                            x: feature.geometry.center.x,
                            y: feature.geometry.center.y,
                            shape: [feature.geometry.center.x, feature.geometry.center.y],
                            isShowCenterPoint: true,
                            isShowCircle: true
                        }
                        this.v_list.push(item);
                    }
                }
            });
        },
        hide() {
            // cleanMarkerListByType('layer');
            // viewer.entities.removeAll()
            g_list_overlay = [];
            if (this.layerName == 't04_zmss_dg') {//对垃圾桶进行特别处理
                viewer.camera.changed.removeEventListener(this.listenHeight);
                this.v_show_zu = false;
            }
            //关闭水库弹框
            if (this.layerName == "t02_slss_sk") {
                if(g_handler_sk){
                    g_handler_sk.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
                }
                //关闭水库的弹窗
                this.$bus.$emit('busEventPop', {name: '', description: '', x: 0, y: 0, z: 0}, false);
            }
            this.$bus.$emit('busEventHideMarker');
            this.v_is_active = false;
        },
        show() {
            // cleanMarkerListByType('layer');
            if (this.type == 'addPoint') {
                var _config = {};
                _config.image = this.markerUrl;
                _config.width = this.width;
                _config.height = this.height;
                if (this.layerName == 't04_zmss_dg') {//对垃圾桶进行特别处理
                    if (!this.checkDataFinish()) {
                        return;
                    }
                    //监听相机高度
                    viewer.camera.changed.addEventListener(this.listenHeight);
                    this.handleMapZoom();
                } else {
                    this.$bus.$emit('busEventShowMarker', this.v_list, this.markerUrl);
                }
                //定位到合适的地图范围
                var _envelope = getEnvelopeByList(this.v_list);
                if (_envelope.length > 3) {
                    viewer.camera.flyTo({
                        destination: Cesium.Rectangle.fromDegrees(
                            _envelope[0],
                            _envelope[1],
                            _envelope[2],
                            _envelope[3]
                        ) //west, south, east, north)
                    });
                }
            } else if (this.type == 'addLine') {
                for (var i = 0; i < this.v_list.length; i++) {
                    var item = this.v_list[i];
                    if (this.layerName == "t04_xcdl") {
                        g_list_overlay.push(addLine('layer', item.id, '', item.shape, null, { lineColor: '#0084ff' }));
                    } else {
                        g_list_overlay.push(addLine('layer', item.id, '', item.shape, null, { lineColor: '#ffc748' }));
                    }
                }
            } else if (this.type == 'addPolygonFunction') {
                for (var i = 0; i < this.v_list.length; i++) {
                    var item = this.v_list[i];
                    var _config = { isFill: true };
                    if (this.layerName == "t02_slss_sk") {
                        _config.polygonFillColor = '#11abff';
                        _config.alpha = 0.7;
                        _config.info = item.bz;
                        _config.name = item.mc;
                        _config.x = item.x;
                        _config.y = item.y;
                        _config.envelope = item.envelope;
                    }
                    g_list_overlay.push(addPolygonFunction('layer', item.id, '', item.shape, null, _config));
                }
                //水库面添加事件
                if (this.layerName == "t02_slss_sk"){
                    g_handler_sk.setInputAction(this.openPop, Cesium.ScreenSpaceEventType.LEFT_CLICK);
                }

            } else if (this.type == 'drawCircle') {
                this.$bus.$emit('busEventShowMarker', this.v_list, this.markerUrl);
                //定位到合适的地图范围
                var _envelope = getEnvelopeByList(this.v_list);
                if (_envelope.length > 3) {
                    viewer.camera.flyTo({
                        destination: Cesium.Rectangle.fromDegrees(
                            _envelope[0],
                            _envelope[1],
                            _envelope[2],
                            _envelope[3]
                        ) //west, south, east, north)
                    });
                }
            }
            if (this.type == 'addLine' || this.type == 'addPolygonFunction'){//除点，其他采用此方式定位
                    var heading = Cesium.Math.toRadians(0.0);
                    var pitch = Cesium.Math.toRadians(-90.0);
                    viewer.flyTo(g_list_overlay[0], {
                        duration: 5,
                        offset: new Cesium.HeadingPitchRange(heading, pitch)
                    });
            }
            this.v_is_active = true;
        },

        openPop(movement){
            var pick = viewer.scene.pick(movement.position);
            if (Cesium.defined(pick)) {
                if (pick.id) {
                    //选中
                    var _entity = pick.id;
                    var _index = _entity.data.id;
                    if (this.layerName == "t02_slss_sk"){
                        var _pop_message = {};
                        _pop_message.name = _entity.data.name; //'水库';
                        _pop_message.x = _entity.data.x;
                        _pop_message.y = _entity.data.y;
                        var _html = '';
                        var _line_arr = _entity.data.info.split('<br>');
                        _line_arr.forEach(_line => {
                            _html += '<div style="margin-bottom: 5px;">';
                            var _wordArr = _line.split(/[：:]/);
                            _html += '<span style="color: rgba(255,255,255,0.8)">'+_wordArr[0]+'：</span><span>'+_wordArr[1]+'</span>';
                            _html += '</div>';

                        })
                        _pop_message.description =  _html;
                        this.$bus.$emit('busEventPop', _pop_message, true);
                    }
                }
            }
        },

        //用于检查小组的垃圾桶计数是否全部加载完，有一个没完成，返回false
        checkDataFinish: function () {
            for (var i = 0; i < this.v_zu_list.length; i++) {
                var _item = this.v_zu_list[i];
                if (!_item.finish) {
                    return false;
                }
            }
            return true;
        },

        requestCunXiaoZuList: function () {
            var _str_filter = "SSCID= '" + this.g_xqid + "' ";
            var _sqlParameter = {
                "getFeatureMode": "SQL",
                "datasetNames": ["p" + this.g_dsz_id + ":t02_zu"],
                "queryParameter": {
                    "attributeFilter": _str_filter,
                    "fields": ["id", "mc", "cx", "cy"],
                    "orderby": 'id'
                },
                "maxFeatures": 100
            }
            http.requestIServerWithPage(_sqlParameter, 0, 100, (result) => {
                // console.log('--村小组回调--', result);
                if (result.features.length > 0) {
                    this.v_zu_list = [];
                    for (var i = 0; i < result.features.length; i++) {
                        var _feature = result.features[i];
                        var _lonLatArr = getLonLatArray(_feature.geometry.points);
                        this.v_zu_list.push({
                            id: i,
                            mc: _feature.fieldValues[1],
                            x: parseFloat(_feature.fieldValues[2]), //_lonLatArr[0],
                            y: parseFloat(_feature.fieldValues[3]), //_lonLatArr[1],
                            count: 0,
                            finish: false,
                            geometry: _feature.geometry,
                            shape: _lonLatArr
                        });
                        this.requestZuFeatureCount(i, _feature.geometry);
                    }

                }
            });
        },
        //执行包含查询
        requestZuFeatureCount: function (index, geometry) {
            //
            var _sqlParameter = {
                "getFeatureMode": "SPATIAL",
                "datasetNames": ["p" + this.g_dsz_id + ':' + this.layerName],
                'spatialQueryMode': "CONTAIN",
                "queryParameter": { fields: ["smid", "mc", "sscid"] },
                'hasGeometry': true,
                'geometry': geometry
            }
            http.requestIServerWithPage(_sqlParameter, 0, 1000, (result) => {
                //console.log('--包含查询回调--', this.layerName, result.totalCount);
                this.v_zu_list[index].count = result.featureCount;
                this.v_zu_list[index].mc = this.v_zu_list[index].mc + this.title + this.v_zu_list[index].count + '个';
                this.v_zu_list[index].finish = true;
            });
        },

        doParentFunctionForToggle(){
            this.$emit("toggleOverlay");
        }
    },
    created() { },
    mounted() {
        let pMountedTimer = window.setInterval(() => {
            if (window.parentMounted) {
                window.clearInterval(pMountedTimer)
                this.init();
            }
        }, 500)
    },
    destroyed() {
        cleanMarkerListByType('layer');
        cleanMarkerListByType('zu_marker_layer');
        this.v_show_zu = false;
        this.v_zu_list = [];
        g_list_overlay = [];
        if (this.layerName == 't04_zmss_dg') {//对垃圾桶进行特别处理
            viewer.camera.changed.removeEventListener(this.listenHeight);
        }
        if(g_handler_sk){console.log('******destroyed*****');
            g_handler_sk.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
        }
    }
}
</script>
<style lang="scss">
.reservoir_des{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .des_lis{
        span{
            color: rgba(225, 242, 245, 0.8);
        }
        cite{
            color: #fff;
        }
    }
}
.tooltip-width {
    max-width: 3rem;
}
</style>
<style lang='scss' scoped>
.item-active{
    background-color: #09fafa50;
}
.item-one {
    cursor: pointer;
    &:hover {
        background-color: #09fafa20;
    }
    padding: 0.1rem;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    flex-shrink: 0;
    border-top: 1px solid;
    cursor: pointer;
    border-image: -webkit-linear-gradient(
            90deg,
            rgba(0, 255, 255, 1),
            rgba(0, 255, 255, 0)
        )
        1 1;
    border-image: -moz-linear-gradient(
            90deg,
            rgba(0, 255, 255, 1),
            rgba(0, 255, 255, 0)
        )
        1 1;
    border-image: -o-linear-gradient(
            90deg,
            rgba(0, 255, 255, 1),
            rgba(0, 255, 255, 0)
        )
        1 1;
    border-image: linear-gradient(
            90deg,
            rgba(0, 255, 255, 1),
            rgba(0, 255, 255, 0)
        )
        1 1;
    .img {
        width: 0.5rem;
        height: 0.5rem;
        img {
            width: 100%;
            height: 100%;
            vertical-align: top;
        }
    }
    .right {
        p {
            font-weight: bold;
            color: #ffffff;
            font-size: 0.18rem;
            text-align: right;
        }
        .name {
            color: #e1f2f5;
            font-size: 0.12rem;
        }
    }
}
.infrastructure-item {
    cursor: pointer;
    &:hover {
        background-color: #09fafa20;
    }
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    flex-shrink: 0;
    border-top: 1px solid;
    border-bottom: 1px solid;
    cursor: pointer;
    border-image: linear-gradient(
            273deg,
            rgba(51, 255, 238, 0),
            rgba(51, 255, 238, 1),
            rgba(51, 255, 238, 0)
        )
        1 1;
    margin-bottom: 0.08rem;
    padding: 0.04rem 0 0 0.16rem;
    .img {
        width: 0.5rem;
        height: 0.5rem;
        margin-right: 0.15rem;
        img {
            height: 100%;
            width: 100%;
        }
    }
    p.bg {
        margin-right: 0.45rem;
        position: relative;
        color: #ffffff;
        height: 0.16rem;
        font-size: 0.12rem;
        width: 0.72rem;
        text-align: center;
        padding-top: 0.15rem;
        &::after {
            content: '';
            width: 100%;
            height: 0.08rem;
            background: linear-gradient(
                180deg,
                rgba(9, 98, 229, 0) 0%,
                #18f5f5 100%
            );
            position: absolute;
            bottom: -0.03rem;
            left: 0;
        }
    }
    .amount {
        & > p {
            font-size: 0.12rem;
            color: rgba(225, 242, 245, 0.8);
            line-height: 0.16rem;
        }
        h3 {
            font-size: 0.2rem;
            color: #ffffff;
            line-height: 0.24rem;
        }
    }
}
.sewage-item {
    cursor: pointer;
    &:hover {
        background-color: #09fafa20;
    }
    margin-right: 0.14rem;
    cursor: pointer;
    &:last-child {
        margin-left: 0;
    }
    .img {
        width: 1.04rem;
        height: 1rem;
        img {
            width: 100%;
            height: 100%;
        }
    }
    & > p {
        text-align: center;
        font-size: 0.12rem;
        line-height: 0.16rem;
        color: #ffffff;
    }
    & > h3 {
        font-size: 0.24;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        cite {
            font-size: 0.12rem;
            font-style: normal;
        }
    }
}
.appearance-item {
    cursor: pointer;
    &:hover {
        background-color: #09fafa20;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 0.08rem;

    .img {
        height: 0.73rem;
        width: 0.88rem;
        padding: 0.04rem;
        position: relative;
    }
    .content {
        // position: relative;
        margin-left: 0.08rem;
        .title {
            margin-top: 0;
            //
            height: 0.2rem;
            font-size: 0.14rem;
            font-weight: 500;
            color: #00ffff;
            line-height: 0.2rem;
            -webkit-background-clip: text;
            padding-left: 0.2rem;
            position: relative;
            // margin-top: 0.08rem;
            &::before {
                content: '';
                width: 0.06rem;
                height: 0.06rem;
                background: #18f5f5;
                position: absolute;
                left: 0.03rem;
                top: 0.08rem;
            }
            &.col_f {
                color: #fff;
            }
        }
        .button {
            position: absolute;
            top: 0.04rem;
            right: 0;
            width: 0.5rem;
            height: 0.18rem;
        }
        p {
            font-size: 0.12rem;
            line-height: 0.18rem;
            color: #ebeff5;
            margin-top: 0.04rem;
            line-height: 0.18rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3; // 想要超出三行显示 就把这里改成3就好了
        }
    }
}
.cls-pop-message-body{
    margin-top: 15px;
}
.cls-pop-sk{
    font-size: 18px;
    color: #ffffff;
    line-height: 24px;
    padding: 0.1rem 0 ;
    text-align: center;
}
</style>