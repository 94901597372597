import axios from "../axios/index"

// 环境图
export const getEnvironmentData = (type) => {
    let tenantId = localStorage.getItem('tenantId')
    return axios.get('/onemap/portal/context/diagramList?pageNum=1&pageSize=10&tenantId='+tenantId+'&environmentalType='+type+'&moduleType=1')
}

// 获取厕所革命数据
export const getToiletData = () => {
    let tenantId = localStorage.getItem('tenantId')
    return axios.get('/onemap/portal/context/toiletList?pageNum=1&pageSize=10&tenantId='+tenantId)
}