<!-- 环境图 -->
<template>
    <div>
        <div class="page_temp page_temp_left">
            <div class="appearance">
                <item-title :name="'村容村貌'" english="Village Appearance" />
                <div class="appearance-box scrollbar">
                    <line-border-box type="bottom" />
                    <div class="content_img_box appearance_content">
                        <div
                            style="width: 100%;overflow: hidden;"
                            v-for="(item, index) in appearanceList"
                            :key="index"
                        >
                            <environment-item
                                @toggleOverlay="changeIfShow(item)"
                                :title="item.title"
                                :environmentalType="item.environmentalType"
                                :detailContent="item.detailContent"
                                :layerName="
                                    item.type == 'addPoint' && !item.layerName
                                        ? 't03_xqd'
                                        : item.layerName
                                "
                                :ifshow="item.ifShow == 2 ? true : false"
                                :markerUrl="item.markerUrl"
                                :type="item.type"
                                :img="item.img"
                                :queryParameter="
                                    item.queryParameter
                                        ? item.queryParameter
                                        : item.type == 'addPoint' &&
                                          !item.layerName
                                        ? `mc='${item.title}'`
                                        : ''
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="lavatory" style="position: relative">
                <item-title
                    :name="'厕所革命'"
                    english="Source Han Sans CN-Medium"
                />
                <div class="content_img_box">
                    <line-border-box type="bottom" />
                    <div
                        style="
                            display: flex;
                            padding-right: 0.4rem;
                            justify-content: space-between;
                            width: 100%;
                        "
                    >
                        <div v-for="(item, index) in toiletList" :key="index">
                            <div class="clickContent">
                                <arc-environment
                                    :index="index"
                                    :singular="item.value"
                                    :sum="toiletList.total"
                                    :color="item.color"
                                />
                                <div class="round">{{ item.value }}</div>
                            </div>
                            <div class="count">
                                <p>{{ item.name }}</p>
                                <h3>{{ item.value }} <cite>所</cite></h3>
                            </div>
                            <!-- <div>
                                <span>{{ item.value }}</span
                                >个
                            </div>
                            <span>{{ item.name }}</span> -->
                        </div>
                    </div>
                </div>
                <percentage :width="Number(width)" type="percentage" />
            </div>
            <div class="water" style="margin-top: 0.08rem">
                <item-title
                    :name="'乡村水利'"
                    english="Rural water conservancy"
                />
                <div class="content_img_box">
                    <line-border-box type="bottom" />
                    <div
                        class="item"
                        v-for="(item, index) in waterList"
                        :key="index"
                        style="width: 48%"
                    >
                        <environment-item
                            @toggleOverlay="changeIfShow(item)"
                            :title="item.title"
                            :environmentalType="item.environmentalType"
                            :layerName="
                                item.type == 'addPoint' && !item.layerName
                                    ? 't03_xqd'
                                    : item.layerName
                            "
                            :ifshow="item.ifShow == 2 ? true : false"
                            :markerUrl="item.markerUrl" :width="35" :height="40"
                            :type="item.type"
                            :img="item.img"
                            :queryParameter="
                                item.queryParameter
                                    ? item.queryParameter
                                    : item.type == 'addPoint' && !item.layerName
                                    ? `mc='${item.title}'`
                                    : ''
                            "
                        />
                    </div>
                </div>
            </div>
            <!-- <div v-if="menu_active == 2">
                <item-title :name="'厕所革命'" />
                <div class="content_img_box">
                    <div
                        style="
                            display: flex;
                            padding-right: 0.4rem;
                            justify-content: space-between;
                            width: 100%;
                        "
                    >
                        <div
                            v-for="(item, index) in toiletList"
                            :key="index"
                            class="clickContent"
                        >
                            <div>
                                <span>{{ item.value }}</span
                                >个
                            </div>
                            <span>{{ item.name }}</span>
                        </div>
                    </div>
                </div>
                <percentage :width="Number(width)" type="percentage" />
            </div> -->
        </div>
        <div class="page_temp page_temp_right">
            <div class="contentBox">
                <div class="infrastructure">
                    <item-title
                        name="乡村基建"
                        english="rural infrastructure"
                    />

                    <div class="content_img_box">
                        <line-border-box type="bottom" />
                        <div
                            style="width: 100%"
                            v-for="(item, index) in infrastructureList"
                            :key="index"
                            
                        >
                            <environment-item
                                :title="item.title"
                                :unit="item.unit"
                                :environmentalType="item.environmentalType"
                                :layerName="
                                    item.type == 'addPoint' && !item.layerName
                                        ? 't03_xqd'
                                        : item.layerName
                                "
                                :ifshow="item.ifShow == 2 ? true : false"
                                :markerUrl="item.markerUrl"  @toggleOverlay="changeIfShow(item)" :width="35" :height="40"
                                :type="item.type"
                                :img="item.img"
                                :queryParameter="
                                    item.queryParameter
                                        ? item.queryParameter
                                        : item.type == 'addPoint' &&
                                          !item.layerName
                                        ? `mc='${item.title}'`
                                        : ''
                                "
                            ></environment-item>
                        </div>
                    </div>
                </div>
                <div class="sewage">
                    <item-title name="垃圾污水" english="Garbage sewage" />
                    <div class="content_img_box" style="flex-wrap: nowrap">
                        <line-border-box type="bottom" />
                        <div
                            v-for="(item, index) in sewageList"
                            :key="index"
                            
                        >
                            <environment-item
                                @toggleOverlay="changeIfShow(item)" 
                                :width="26" 
                                :height="30"
                                :title="item.title"
                                :environmentalType="item.environmentalType"
                                :layerName="
                                    item.type == 'addPoint' && !item.layerName
                                        ? 't03_xqd'
                                        : item.layerName
                                "
                                :ifshow="item.ifShow == 2 ? true : false"
                                :markerUrl="item.markerUrl"
                                :type="item.type"
                                :img="item.img"
                                :queryParameter="
                                    item.queryParameter
                                        ? item.queryParameter
                                        : item.type == 'addPoint' &&
                                          !item.layerName
                                        ? `mc='${item.title}'`
                                        : ''
                                "
                            ></environment-item>
                        </div>
                    </div>
                </div>
                <div class="power">
                    <item-title
                        name="乡村电力"
                        english="Source Han Sans CN-Medium"
                    />

                    <div class="content_img_box">
                        <line-border-box type="bottom" />
                        <div
                            class="item"
                            v-for="(item, index) in powerList"
                            :key="index"
                            style="width: 48%"
                        >
                            <environment-item
                                :title="item.title"
                                :environmentalType="item.environmentalType"
                                :layerName="
                                    item.type == 'addPoint' && !item.layerName
                                        ? 't03_xqd'
                                        : item.layerName
                                "
                                :ifshow="item.ifShow == 2 ? true : false"
                                :markerUrl="item.markerUrl" :width="35" :height="40"
                                :type="item.type"
                                :img="item.img"
                                :queryParameter="
                                    item.queryParameter
                                        ? item.queryParameter
                                        : item.type == 'addPoint' &&
                                          !item.layerName
                                        ? `mc='${item.title}'`
                                        : ''
                                "
                                @toggleOverlay="changeIfShow(item)"
                            ></environment-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <scrollBar></scrollBar> -->
        <div class="btn-box">
            <!-- 森林防火 -->
           <a href="#" @click="requestVideoData()" class="btn-img" v-if="isShow"></a>
        </div>
        <dot-pop :name="'视频'" v-show="v_if_show_video" :width="800" :height="500" type="video" :x="v_video.x" :y="v_video.y" :z="1" @closePane="onCloseVideo">
			<div id="id_video_play" style="width:100%;height:100%;display:none"></div>
			<easy-player id="player" live="true" show-custom-button="true" isH265="true" style="width:100%;height:100%;"></easy-player>
		</dot-pop>

        <!-- 通用标注 -->
        <div v-for="(item, index) in v_marker_data" v-if="v_bln_show_marker" :key="index">
            <dot-pop  type="dot" :name="item.mc" :x="item.x" :y="item.y" :z="1">
                <img style="width: 30px" :src="markerUrl" @click="showImg(item)"/>
            </dot-pop>
        </div>

         <!-- 显示图片 -->
        <dot-pop :name="title" v-show="v_if_show_img"  type="img" :x="v_item.x" :y="v_item.y" :z="1" :width="500" :height="400" @closePane="onCloseImg">
			<img :src="img" style="height:370px" />
		</dot-pop>
        <!-- 通用信息弹窗-水库 -->
		<dot-pop :name="v_pop_message.name" :width="400" :height="300" :x="v_pop_message.x" :y="v_pop_message.y" :z="v_pop_message.z"  v-if="v_show_pop" @closePane="v_show_pop=false">
            <div class="cls-pop-message-body">
                <!-- <div class="cls-pop-sk">小微水体“一长两员”公示牌</div> -->
                <div v-html="v_pop_message.description"></div>
            </div>
		</dot-pop>
    </div>
</template>

<script>
import itemTitle from '../components/item-title.vue'
import scrollBar from '../components/scrollBar.vue'
import lineBorderBox from "@/components/line-border-box.vue"
import {
    mapState
} from 'vuex';
import commonLayerQueryTool from '@/components/commonLayerQueryTool.vue';
import { cleanMarkerListByType } from '@/util/drawShape.js';
import dotPop from "@/components/dot-pop.vue"
import { getEnvironmentData, getToiletData } from '@/api/environment.js'
import percentage from '@/components/percentage.vue'
import environmentItem from "@/components/environmentItem.vue";
import arcEnvironment from "@/components/arcEnvironment.vue"
import http from '../util/http.js';
import {getDiShiZhouCodeByCunCode} from '../util/common.js';

export default {
    components: {
        itemTitle,
        scrollBar,
        commonLayerQueryTool,
        dotPop,
        percentage, lineBorderBox, environmentItem, arcEnvironment
    },
    data: function () {
        return {
            isShow:false,
            hbgc: true,
            // 当前显示的类型
            layerName: '',
            listData: [],
            appearanceList: [],
            waterList: [],
            infrastructureList: [],
            sewageList: [],
            powerList: [],
            typeName: '村容村貌',
            colorList: ['#3ea2ff', '#ffc748'],
            toiletList: [{
                value: 0,
                name: '已改造',
                color: '#3691E8'
            }, {
                value: 0,
                name: '待改造',
                color: '#1DE5E7'
            }],

            width: '100',

            v_if_show_video: false,
            player: Function,
            v_video_url: '',//EasyPlayer
            v_video: {x: 0, y: 0},
            g_xqid: localStorage.getItem('tenantCode'),
            g_dsz_id: getDiShiZhouCodeByCunCode(localStorage.getItem('tenantCode'))
            ,
            environmentalType: 0,
            img: '',
            title: '',
            markerUrl: require('@/assets/marker/location.png'),
            v_if_show_img: false,
            v_item: {x: 0, y: 0},
            v_bln_show_marker: false,
            v_marker_data: [],//自定义的标注数据集合
            v_show_pop: false,
            v_pop_message: {name: '', description: '', x: 0, y: 0, z: 0}
        }
    },
    computed: {
        ...mapState(['menu_active'])
    },
    watch: {
        menu_active: function (newValue, oldValue) {
            // this.getListData()
        }
    },
    mounted() {
    this.isShow = localStorage.getItem('tenantCode') == '421224107205'
        let pMountedTimer = window.setInterval(() => {
            if (window.parentMounted) {
                window.clearInterval(pMountedTimer)
                this.init()
            }
        }, 500)
        this.$bus.$on('busEventShowMarker', (list, url)=>{
              this.v_marker_data = list;
            this.markerUrl = url;
            this.v_bln_show_marker = true;
        });
        this.$bus.$on('busEventHideMarker', ()=>{
            //console.log('bus->hide:');
        });
        //显示或隐藏水库弹框
        this.$bus.$on('busEventPop', (msg, ifShow)=>{
            //console.log('bus->pop:');
            this.v_pop_message = msg;
            this.v_show_pop = ifShow;
        });
    },
    methods: {
        init: function () {
            this.$parent.show2DMap();
            // 获取列表数据
            // this.getListData()
            this.initToilet()
            this.getList(1)
            this.getList(5)
            this.getList(6)
            this.getList(3)
            this.getList(4)
        },
        getList(type) {
            getEnvironmentData(type).then(res => {
                if (type == 1) {
                    this.appearanceList = res.rows
                } else if (type == 2) {
                    this.lifeList = res.rows
                } else if (type == 3) {
                    this.sewageList = res.rows
                }
                else if (type == 4) {
                    this.powerList = res.rows
                } else if (type == 5) {
                    this.waterList = res.rows
                } else if (type == 6) {
                    this.infrastructureList = res.rows
                }
                this.listData = res.rows
                if (this.menu_active != 1) {
                    cleanMarkerListByType('layer');
                }
            })
        },
        getListData: function () {
            this.listData = []
            let type = this.menu_active
            switch (type) {
                case 1: this.typeName = '村容村貌'; break;
                case 2: this.typeName = '厕所革命';
                    this.initToilet()
                    break;
                case 3: this.typeName = '垃圾污水'; break;
                case 4: this.typeName = '乡村电力'; break;
                case 5: this.typeName = '乡村水利'; break;
                case 6: this.typeName = '乡村基建'; break;
            }
            getEnvironmentData(type).then(res => {
                this.listData = res.rows
                if (this.menu_active != 1) {
                    cleanMarkerListByType('layer');
                }
            })
        },
        initToilet: function () {
            getToiletData().then(res => {
                if (res.rows.length > 0) {
                    this.toiletList[0].value = parseInt(res.rows[0].toiletReformed)
                    this.toiletList[1].value = parseInt(res.rows[0].toiletUnmodified)
                    this.toiletList.total = res.rows[0].toiletReformed + res.rows[0].toiletUnmodified
                    this.width = (res.rows[0].toiletReformed / (res.rows[0].toiletReformed + res.rows[0].toiletUnmodified) * 100).toFixed(2)
                }
            })
        },

        requestVideoData: function(){
            console.log('---森林防火摄像头---');
            if(this.v_video.mc){//如果已经缓存了信息，就不用再请求图层信息
                this.showVideoWindow();
                return;
            }
            var _sqlParameter = {
                "getFeatureMode": "SQL",
                "datasetNames": ["p" + this.g_dsz_id + ":t05_abss_sxt"],
                "queryParameter": {
                    "attributeFilter": "SSCID= '" + this.g_xqid + "' and mc='森林防火摄像头'",
                    "fields": ["smid", "mc", "url", "json", "scwz"],
                    "orderby": 'id'
                },
                "maxFeatures": 10
            }
            http.requestIServerWithPage(_sqlParameter, 0, 10, (result) => {
                console.log('--森林防火摄像头 视频回调--', result.totalCount);
                if (result.features.length > 0) {
                    for (var i = 0; i < result.features.length; i++) {
                        var _feature = result.features[i];
                        this.v_video.x = _feature.geometry.center.x;
                        this.v_video.y = _feature.geometry.center.y;
                        this.v_video.mc = _feature.fieldValues[1];  //缓存信息
                        this.v_video_url = _feature.fieldValues[2];
                        this.showVideoWindow();
                        break;
                    }
                }
            });
        },
        showVideoWindow: function(){
            if(this.v_video_url){
                this.v_if_show_video = true;
                this.$nextTick(()=>{this.baiduMp4(this.v_video_url);});
                //缩放到合适范围
                var hcv = {
                    destination: Cesium.Cartesian3.fromDegrees(this.v_video.x, this.v_video.y, 2500)
                }
                viewer.camera.flyTo(hcv);
            }
        },
                //播放视频链接
        baiduMp4(mp4Url) {
            console.log('--video---', mp4Url);
            document.getElementById('player').setAttribute('video-url', mp4Url);
        },

        showImg(item){
            this.img = item.img;
            this.environmentalType = item.environmentalType;
            this.title = item.title;
            if(this.environmentalType != 1){
                this.v_if_show_img = false;
                return;
            }
            if(this.img){
                if(this.v_if_show_img){
                    this.v_if_show_img = false;
                }else{
                    this.v_item.x = item.x;
                    this.v_item.y = item.y;
                    this.v_if_show_img = true;
                }
            }
        },
        onCloseImg() {
            this.v_if_show_img = false;
        },

        onCloseVideo() {
            if(document.getElementById('player'))
                document.getElementById('player').setAttribute('video-url', "");
            this.v_if_show_video = false;
        },
             
        changeIfShow(item) {
            this.layerName = item.layerName
            viewer.entities.removeAll()
            let type = item.environmentalType
            let list = [];
            switch (type) {
                case 1:
                    list = this.appearanceList;
                    break;
                case 2: this.typeName = '厕所革命';
                    this.initToilet()
                    break;
                case 3:
                    list = this.sewageList;
                    break;
                case 4:
                    list = this.powerList;
                    break;
                case 5:
                    list = this.waterList;
                    break;
                case 6:
                    list = this.infrastructureList;
                    break;
            }
            if(type != 1){this.appearanceList.forEach(item => item.ifShow = 1);}
            if(type != 3){this.sewageList.forEach(item => item.ifShow = 1);}
            if(type != 4){this.powerList.forEach(item => item.ifShow = 1);}
            if(type != 5){this.waterList.forEach(item => item.ifShow = 1);}
            if(type != 6){this.infrastructureList.forEach(item => item.ifShow = 1);}
            list.forEach(i => {
                if (item != i) {
                    i.ifShow = 1;
                }
            })
            this.v_marker_data = [];
            this.v_bln_show_marker = false;
            this.v_if_show_img = false;
            item.ifShow == 1 ? item.ifShow = 2 : item.ifShow = 1;
        }
    },
    destroyed() {
        cleanMarkerListByType('layer');
        this.v_marker_data = [];
        this.v_bln_show_marker = false;
        this.v_if_show_img = false;
    }
}
</script>

<style scoped lang="scss">
.btn-box{
  position: absolute;
  bottom: 0.52rem;
  right: 4.2rem;
//   left: calc(100%/2 - 0.5rem);
  z-index: 9;
  .btn-img{
    display: inline-block;
    width: 0.8rem;
    height:  0.8rem;
    background: url(../../static/img/02.png) no-repeat;
    background-size: 100% 100%;
    &:hover{
      background: url(../../static/img/022.png) no-repeat;
       background-size: 100% 100%;  
    }
  }
}
.title {
    height: 0.2rem;
    font-size: 0.14rem;
    font-weight: 500;
    color: #00ffff;
    line-height: 0.2rem;
    -webkit-background-clip: text;
    padding-left: 0.2rem;
    position: relative;
    margin-top: 0.08rem;
    &::before {
        content: '';
        width: 0.06rem;
        height: 0.06rem;
        background: #18f5f5;
        position: absolute;
        left: 0.03rem;
        top: 0.08rem;
    }
    &.col_f {
        color: #fff;
    }
}
.appearance {
    position: relative;
    padding-bottom: 0.08rem;
    margin-bottom: 0.08rem;
    .appearance-box {
        max-height: 3.6rem;
        overflow: auto;
    }
}
.content_img_box {
    width: 98%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0.1rem;
    box-sizing: border-box;
}

.toilet-box {
    width: 100%;
    display: flex;
    margin: 0.2rem 0;

    .item {
        width: 1.65rem;
        height: 0.7rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 0.16rem;
        cursor: pointer;

        div {
            width: 100%;
            height: 0.45rem;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            background-image: url(../assets/img/server/b2.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            font-size: 0.14rem;
            color: #fff;

            span {
                font-size: 0.24rem;
            }
        }
    }

    .item:last-child {
        div {
            background-image: url(../assets/img/server/b1.png);
        }
    }
}

.toilet-box > .item > span {
    color: #fff;
    font-size: 0.16rem;
    line-height: 0.3rem;
}

.pie_echarts_box {
    width: 100%;
    height: 2rem;

    #pie_echarts {
        width: 100%;
        height: 100%;
    }
}

.toilet {
    width: 100%;
    display: flex;
    justify-content: space-around;
    font-size: 0.16rem;
    color: #fff;
    padding: 0 0.3rem;

    div {
        display: flex;
        align-items: center;
        margin: 0.2rem 0;

        span {
            display: flex;
            width: 0.1rem;
            height: 0.1rem;
            border-radius: 50%;
            margin-right: 0.1rem;
        }
    }
}

.wrapper {
    font-size: 12px;
    color: #555;
}

.content_img_item {
    width: 1.63rem;
    height: 1.45rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0.2rem;
    cursor: pointer;

    div {
        width: 1.6rem;
        height: 1.2rem;
        background-color: #ffffff;
        box-shadow: 0px 0px 10px 1px rgba(228, 255, 254, 0.3);
        border-radius: 0.04rem;
        border: solid 1px #fff;
    }

    div:hover {
        border: solid 1px #ffc748;
    }

    span {
        font-size: 0.14rem;
        color: #fff;
        line-height: 0.3rem;
    }

    .cls-selected {
        cursor: pointer;
    }
}

.clickContent {
    width: 1.26rem;
    height: 1rem;
    // background-image: url(../assets/img/server/a2.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-size: 0.16rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin: 0 0.1rem;
    .round {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        width: 0.54rem;
        height: 0.54rem;
        border-radius: 50%;
        backdrop-filter: blur(0.02rem);
        border: 0.02Crem solid rgba(225, 225, 225, 0.2);
    }
    div {
        font-size: 0.14rem;
        color: #ffc748;

        span {
            font-size: 0.24rem;
            margin-right: 0.04rem;
        }
    }

    cursor: pointer;
}
.count {
    text-align: center;
    & > p {
        font-size: 0.12rem;
        color: rgba(255, 255, 255, 0.6);
        line-height: 0.16rem;
    }
    & > h3 {
        color: #ffffff;
        line-height: 0.24rem;
        font-size: 0.24rem;
        text-indent: 0.2rem;
        margin-top: 0.03rem;
        cite {
            color: #ffffff;
            font-size: 0.12rem;
            font-style: normal;
        }
    }
}

.clickContent:hover {
    // background-image: url(../assets/img/server/a1.png);
}

.clickContent > span {
    margin: 0.06rem 0;
}
.water {
    padding-bottom: 0.05rem;
    .item {
        margin-bottom: 0.1rem;
        .item-one {
            padding: 0.1rem;
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            flex-shrink: 0;
            border-top: 1px solid;
            border-image: -webkit-linear-gradient(
                    90deg,
                    rgba(0, 255, 255, 1),
                    rgba(0, 255, 255, 0)
                )
                1 1;
            border-image: -moz-linear-gradient(
                    90deg,
                    rgba(0, 255, 255, 1),
                    rgba(0, 255, 255, 0)
                )
                1 1;
            border-image: -o-linear-gradient(
                    90deg,
                    rgba(0, 255, 255, 1),
                    rgba(0, 255, 255, 0)
                )
                1 1;
            border-image: linear-gradient(
                    90deg,
                    rgba(0, 255, 255, 1),
                    rgba(0, 255, 255, 0)
                )
                1 1;
            .img {
                width: 0.5rem;
                height: 0.5rem;
                img {
                    width: 100%;
                    height: 100%;
                    vertical-align: top;
                }
            }
            .right {
                p {
                    font-weight: bold;
                    color: #ffffff;
                    font-size: 0.18rem;
                }
                .name {
                    color: #e1f2f5;
                    font-size: 0.12rem;
                }
            }
        }
    }
}
.infrastructure {
    padding-bottom: 0.08rem;
    position: relative;
    margin-bottom: 0.18rem;
}
.sewage {
    // position: relative;
    padding-bottom: 0.08rem;
    .content_img_box {
        margin-bottom: 0.1rem;
        padding-left: 0.08rem;
    }
}
.power {
    margin-top: 0.15rem;
    .content_img_box {
        padding-left: 0.08rem;
    }
    .item {
        margin-bottom: 0.1rem;
    }
}
</style>
